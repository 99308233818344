<template>
  <div class="container-fluid">
    <section class="green__stripe">
      <div class="container">
        <v-row class="d-flex justify-space-between align-center">
          <v-col
            cols="12"
            md="7"
            class="d-flex flex-column justify-start align-center"
          >
            <Card borderColor="#e06919" class="mb-5" style="z-index: 2;">
              <template v-slot:conteudo>
                <p>
                  O distrito de Monsenhor Horta pertence ao município de
                  Mariana/MG e possui como subdistritos
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_paracatu_de_cima' }"
                    >Paracatu de Cima</router-link
                  >,
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_paracatu_de_baixo' }"
                    >Paracatu de Baixo</router-link
                  >
                  e
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_ponte_do_gama' }"
                    >Ponte do Gama</router-link
                  >. Situa-se a cerca de 16km do centro de Mariana e a 129 km de
                  Belo Horizonte, capital do Estado de Minas Gerais.
                </p>
                <p>
                  A formação do povoamento remete ao final do século XVII,
                  quando o bandeirante coronel Salvador Fernandes Furtado de
                  Mendonça saiu da região paulista de Taubaté e atravessou o
                  interior de Minas. Ficou conhecido pela descoberta do ouro nos
                  arredores de Mariana, incluindo o local onde surgiu o povoado
                  de São Caetano (atual distrito de Monsenhor Horta). Ele era um
                  devoto fervoroso e tinha permissão do bispo do Rio de Janeiro
                  para levar consigo um altar portátil, bem como para edificar
                  igrejas.
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </div>
    </section>

    <section class="position-rel container">
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-center"
          style="z-index: 1"
        >
          <v-img
            class="position-abs"
            max-width="50%"
            style="left: 2em; opacity: 0.7;"
            src="../../assets/sobre_territorio_campinas_22.png"
          />
        </v-col>
        <v-col
          style="z-index: 1"
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Após alguns anos, em 1703, instalou-se com toda a família às
                margens do Ribeirão do Carmo, em gleba de terras a ele doadas
                pela pela Câmara de Mariana. Ali edificou uma igreja particular
                dedicada a Nossa Senhora de Loreto, onde um padre responsável
                pela região passou a celebrar missas, que eram frequentadas
                pelos moradores locais. Apesar da extração de ouro, consta que a
                vida era extremamente dura para todos os moradores daquelas
                redondezas, havendo épocas em que faltavam inclusive alimentos
                básicos.
              </p>
              <p>
                Com o passar dos anos, surgiu um povoado ao redor da igreja
                construída por Salvador Furtado, e esse lugarejo recebeu o nome
                de São Caetano do Rio do Carmo. O nome do Santo é uma referência
                ao padre italiano Gaetano de Thiene, canonizado em 1671, e
                fundador da ordem dos Clérigos Regulares da Divina Providência.
                A “Divina Providência” é um termo teológico que se refere ao
                poder supremo de Deus para gerir e prover todas as coisas. Por
                ter propagado essa devoção, São Caetano passou a ser também
                considerado patrono dos administradores e demais pessoas que
                necessitavam de provimento e auxílio e, considerando o contexto
                de dificuldades suportadas pelos primeiros moradores daquele
                povoamento, fica mais claro entender o provável sentido de São
                Caetano ter sido escolhido como padroeiro daquela igreja.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          style="z-index: 1"
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O antigo povoado de São Caetano foi criado pela lei provincial
                nº 50, de 8 de abril de 1836. Passou a ter sua denominação atual
                pelo decreto-lei estadual nº 1058, de 31 de dezembro de 1943. A
                mudança em sua denominação ocorreu em homenagem a Monsenhor José
                Silvério Horta, sacerdote marianense com funções
                político-administrativas da Cúria, principalmente como
                secretário do Bispado de Mariana e vigário geral. Foi ainda
                cônego da Sé por um período e exorcista da Diocese. A atuação
                que o retratava como religioso foi sua dedicação aos pobres,
                enfermos e pessoas em busca de consolo espiritual.
              </p>
              <p style="text-align: center;">
                <strong>Autora: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p class="ma-0">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
.green__stripe {
  position: relative;
}

.green__stripe:before {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  height: 110px;
  width: 200%;
  background-color: #04441f;
}

h6 {
  font-size: 0.8em;
}

section {
  margin-bottom: 3em;
}

.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.table__section {
  margin-top: 3em;
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
